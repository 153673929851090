import React from 'react'
import Layout from '../components/Layout'
import Title from "../components/Title"
import styles from "../css/service.module.css"
import AboutFinal from "../constans/abouts"
import SEO from "../components/SEO"

const servicios = () => {
    return (
        <div>
            <Layout>
            <SEO titlePage="About us" />
            <section className={styles.services}>
            <Title title="About us" subtitle="" />
            
            <AboutFinal />
            
            </section>
            </Layout>
            
        </div>
    )
}
export default servicios
