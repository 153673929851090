import styles3 from "../css/banner.module.css"
import React from 'react'
import StyledHero from "../components/StyleHero"
import Banner from "../components/Banner"
import {useStaticQuery, graphql} from 'gatsby'
const getAbout = graphql`query{
  about:allContentfulAbout(sort:{fields: order}){
    edges{
      node{
        title
        contentText{
          childContentfulRichText {
            html
          }
        }
        alignLeft
      }
    }
  }
}`
const Abouts = () => {
  const response = useStaticQuery(getAbout)
  const aboutsQuery = response.about.edges
  return (
    <div>
        {aboutsQuery.map(({node}, index) => {
          let finalText = node.contentText.childContentfulRichText.html
          let finalStyle = node.alignLeft ? styles3.left : styles3.right
          let finalStyleBanner = node.alignLeft ? styles3.contentBannerLeft : styles3.contentBannerRight
          return (
            <div key={index}>
              <StyledHero home="false" >
                <Banner title={node.title} subTitle="" info={finalText} direction={finalStyle} styleContent={finalStyleBanner} >
                </Banner>
              </StyledHero>
            </div>
          )
        })}
    </div>
  )
}
export default Abouts
